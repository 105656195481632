// Generated by Framer (c93245f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I6FhGYSDH", "zbgvnLlZJ"];

const serializationHash = "framer-G6hQ2"

const variantClassNames = {I6FhGYSDH: "framer-v-1vwfkqk", zbgvnLlZJ: "framer-v-1sstbzy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Close: "zbgvnLlZJ", Menu: "I6FhGYSDH"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "I6FhGYSDH", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "I6FhGYSDH", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16y2uw0 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {
const res = await XKaY3MrGu(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1vwfkqk", className, classNames)} data-framer-name={"Menu"} data-highlight layoutDependency={layoutDependency} layoutId={"I6FhGYSDH"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap16y2uw0} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({zbgvnLlZJ: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1h46k8a"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"n_qn7hVks"} style={{backgroundColor: "rgb(23, 23, 23)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{zbgvnLlZJ: {rotate: -45}}}/><motion.div className={"framer-cwd7qk"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"UIxvsux93"} style={{backgroundColor: "rgb(23, 23, 23)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{zbgvnLlZJ: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-G6hQ2.framer-p7offl, .framer-G6hQ2 .framer-p7offl { display: block; }", ".framer-G6hQ2.framer-1vwfkqk { cursor: pointer; height: 60px; overflow: hidden; position: relative; width: 60px; }", ".framer-G6hQ2 .framer-1h46k8a { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(58.33333333333336% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-G6hQ2 .framer-cwd7qk { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(41.66666666666669% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-G6hQ2.framer-v-1sstbzy.framer-1vwfkqk { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 60px); }", ".framer-G6hQ2.framer-v-1sstbzy .framer-1h46k8a, .framer-G6hQ2.framer-v-1sstbzy .framer-cwd7qk { top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 60
 * @framerIntrinsicWidth 60
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zbgvnLlZJ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerF4wmxljEo: React.ComponentType<Props> = withCSS(Component, css, "framer-G6hQ2") as typeof Component;
export default FramerF4wmxljEo;

FramerF4wmxljEo.displayName = "Icon";

FramerF4wmxljEo.defaultProps = {height: 60, width: 60};

addPropertyControls(FramerF4wmxljEo, {variant: {options: ["I6FhGYSDH", "zbgvnLlZJ"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerF4wmxljEo, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})